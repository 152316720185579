
import { Component, Vue } from 'vue-property-decorator';
import groupBy from 'lodash.groupby';
import debounce from 'lodash.debounce';
import moment from 'moment';
import transactionService from '@/services/transactions/transactionService';
import Observer from '@/components/Observer.vue';
import EmptyData from '@/components/EmptyData.vue';

@Component({
  components: {
    Observer,
    EmptyData,
  },
})
export default class TransactionList extends Vue {
  public data: any = [];
  public page = 1;
  public limit = 15;
  public isLoading = true;
  public loadMore = true;
  public observer: any = null;
  public observerOptions: any = {
    rootMargin: '0px',
    threshold: 1,
  };
  public debouncedIntersected = debounce(this.intersected, 500);

  get formatedData(): any {
    const formatedObject = groupBy(this.data, (transaction) => (
      moment(transaction.createdAt).startOf('day').format()
    ));

    return formatedObject;
  }

  formatDate(date: string): string {
    return moment(date).format('DD MMMM Y').toString();
  }

  formatDateToHour(date: string): string {
    return moment(date).locale('fr').format('HH:mm').toString();
  }

  async intersected(): Promise<void> {
    if (this.loadMore) {
      this.isLoading = true;
      try {
        const { data } = await transactionService.findAll(
          +this.page,
          +this.limit,
        );

        this.page += 1;
        if (data.length === 0) this.loadMore = false;
        this.data = [...this.data, ...data];
      } catch (error) {
        const { data } = error.response;
        console.log(Array.isArray(data.message) ? data.message[0] : data.message);
      } finally {
        this.isLoading = false;
      }
    }
  }
}
