import { render, staticRenderFns } from "./EmptyData.vue?vue&type=template&id=74900bc9&scoped=true&"
import script from "./EmptyData.vue?vue&type=script&lang=ts&"
export * from "./EmptyData.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74900bc9",
  null
  
)

export default component.exports