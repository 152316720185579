import { AxiosPromise } from 'axios';
import axiosInstance from '@/helpers/axios.config';

class TransactionService {
  protected get baseUrl(): string {
    return `${process.env.VUE_APP_API_URL}/transactions`;
  }

  findAll(page: number, limit: number): Promise<AxiosPromise> {
    return axiosInstance.get(`${process.env.VUE_APP_API_URL}/users/transactions?page=${page}&limit=${limit}`);
  }
}

export default new TransactionService();
